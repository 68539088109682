export default defineNuxtRouteMiddleware(() => {
  const { user, isAuthenticated } = useAuth()
  const config = useRuntimeConfig()

  if (!isAuthenticated.value) {
    return navigateTo(config.public.api.redirects.onAuthOnly, { replace: true })
  }

  if (user.value?.email_verified_at !== null) {
    return navigateTo(config.public.api.redirects.onUnverifiedOnly, { replace: true })
  }
})
